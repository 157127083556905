import React, { useState, useRef, useEffect } from 'react';
import './InstagramFeedSimulator.css'; // Import CSS for styling
import './player/Player.css';
import LineBreak from './maker/component/LineBreak';
import { animate } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { checkMobile, isIpad, isTikTokInAppBrowser } from './player/Utils';

const InstagramFeedSimulator = () => {    
  const [videoRef1, setVideoRef1] = useState(new URLSearchParams(window.location.search).get("video1") ? new URLSearchParams(window.location.search).get("video1") : "https://storage.googleapis.com/upreality-prod-619c3.appspot.com/demo/demo_background_ad_v2.mp4");
  const [videoRef2, setVideoRef2] = useState(new URLSearchParams(window.location.search).get("video2") ? new URLSearchParams(window.location.search).get("video2") : "https://storage.googleapis.com/upreality-prod-619c3.appspot.com/demo/demo_background_ad.mp4");
  const [imgRef1, setImgRef1] = useState(new URLSearchParams(window.location.search).get("img") ? new URLSearchParams(window.location.search).get("img") : "/images/img_starter.jpg");  
  const [cta, setCta] = useState(new URLSearchParams(window.location.search).get("cta") ? new URLSearchParams(window.location.search).get("cta") : "En savoir plus");    
  const [ctaColor, setCtaColor] = useState(new URLSearchParams(window.location.search).get("ctaColor") ? new URLSearchParams(window.location.search).get("ctaColor") : "813629");    
  const [ctaDest, setCtaDest] = useState(new URLSearchParams(window.location.search).get("ctaDest") ? new URLSearchParams(window.location.search).get("ctaDest") : "CafeUpRealityAd");      
  const [ctaDelay, setCtaDelay] = useState(new URLSearchParams(window.location.search).get("ctaDelay") ? new URLSearchParams(window.location.search).get("ctaDelay") : "6");      
  const [author1, setAuthor1] = useState(new URLSearchParams(window.location.search).get("author1") ? new URLSearchParams(window.location.search).get("author1") : "le_petit_bistro");      
  const [author2, setAuthor2] = useState(new URLSearchParams(window.location.search).get("author2") ? new URLSearchParams(window.location.search).get("author2") : "lagrandebrasserie");      
  const [author3, setAuthor3] = useState(new URLSearchParams(window.location.search).get("author3") ? new URLSearchParams(window.location.search).get("author3") : "cafe_upreality");      
  const [userAvatar, setUserAvatar] = useState(new URLSearchParams(window.location.search).get("avatar1") ? new URLSearchParams(window.location.search).get("userAvatar") : "https://storage.googleapis.com/upreality-prod-619c3.appspot.com/demo/demo_avatar.jpg");      
  const [avatar1, setAvatar1] = useState(new URLSearchParams(window.location.search).get("avatar1") ? new URLSearchParams(window.location.search).get("avatar1") : "https://storage.googleapis.com/upreality-prod-619c3.appspot.com/demo/demo_avatar.jpg");      
  const [avatar2, setAvatar2] = useState(new URLSearchParams(window.location.search).get("avatar2") ? new URLSearchParams(window.location.search).get("avatar2") : "https://storage.googleapis.com/upreality-prod-619c3.appspot.com/demo/demo_avatar.jpg");      
  const [avatar3, setAvatar3] = useState(new URLSearchParams(window.location.search).get("avatar3") ? new URLSearchParams(window.location.search).get("avatar3") : "https://storage.googleapis.com/upreality-prod-619c3.appspot.com/demo/demo_avatar.jpg");      
  const [comment1, setComment1] = useState(new URLSearchParams(window.location.search).get("comment1") ? new URLSearchParams(window.location.search).get("comment1") : "Super vidéo !");      
  const [comment2, setComment2] = useState(new URLSearchParams(window.location.search).get("comment2") ? new URLSearchParams(window.location.search).get("comment2") : "Super vidéo !");      
  const [comment3, setComment3] = useState(new URLSearchParams(window.location.search).get("comment3") ? new URLSearchParams(window.location.search).get("comment3") : "Super vidéo !");      

  const navigate = useNavigate();

  useEffect(() => {              
    const cta = document.getElementById("cta");
    if (cta != null){                                  
        const animation = async () => {   
            await animate(cta, { backgroundColor: "#FFFFFF"}, {duration: parseFloat(ctaDelay)});                    
            animate(cta, { backgroundColor: "#" + ctaColor}, {duration: 0.6}); 
            animate(cta, { color: "#FFFFFF"}, {duration: 0.6});                    
        }
        
        animation();
    }   
    
    document.getElementsByTagName('body')[0].className = 'WhiteBackground';
}, []);

function openFullscreen() {
    var elem = document.documentElement;
    
    if (document.fullscreenElement || window.innerWidth > 480 || ! checkMobile() || isIpad() || isTikTokInAppBrowser()){
      return;
    }
  
    if (elem.requestFullscreen) {
      elem.requestFullscreen().catch(err => {});
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      try {
        elem.webkitRequestFullscreen();
      }
      catch (error) {}
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  return (
    <div className="instagram-container" onClick={()=>{openFullscreen();}}> {/* New container for everything */}
        <div className="instagram-header">  {/* The white header bar */}
            <div className="header-title">Pour vous</div>
            <div className="header-icons">
                <svg aria-label="Accueil" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Accueil</title><path d="M2 12v3.45c0 2.849.698 4.005 1.606 4.944.94.909 2.098 1.608 4.946 1.608h6.896c2.848 0 4.006-.7 4.946-1.608C21.302 19.455 22 18.3 22 15.45V8.552c0-2.849-.698-4.006-1.606-4.945C19.454 2.7 18.296 2 15.448 2H8.552c-2.848 0-4.006.699-4.946 1.607C2.698 4.547 2 5.703 2 8.552Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path><line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="6.545" x2="17.455" y1="12.001" y2="12.001"></line><line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12.003" x2="12.003" y1="6.545" y2="17.455"></line></svg>
                <div className="heart-icon-container">
                    <svg aria-label="Notifications" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Notifications</title><path d="M16.792 3.904A4.989 4.989 0 0 1 21.5 9.122c0 3.072-2.652 4.959-5.197 7.222-2.512 2.243-3.865 3.469-4.303 3.752-.477-.309-2.143-1.823-4.303-3.752C5.141 14.072 2.5 12.167 2.5 9.122a4.989 4.989 0 0 1 4.708-5.218 4.21 4.21 0 0 1 3.675 1.941c.84 1.175.98 1.763 1.12 1.763s.278-.588 1.11-1.766a4.17 4.17 0 0 1 3.679-1.938m0-2a6.04 6.04 0 0 0-4.797 2.127 6.052 6.052 0 0 0-4.787-2.127A6.985 6.985 0 0 0 .5 9.122c0 3.61 2.55 5.827 5.015 7.97.283.246.569.494.853.747l1.027.918a44.998 44.998 0 0 0 3.518 3.018 2 2 0 0 0 2.174 0 45.263 45.263 0 0 0 3.626-3.115l.922-.824c.293-.26.59-.519.885-.774 2.334-2.025 4.98-4.32 4.98-7.94a6.985 6.985 0 0 0-6.708-7.218Z"></path></svg>
                    <span className="notification-badge"></span> {/* The red dot */}
                </div>
            </div>
        </div>
        <div className="instagram-feed">
        {/* Video Content 1 */}
            <div className="feed-item video">
                <div className="post-header"> {/* New header for each post */}
                    <div className="post-header-left">
                    <img style={{width: 32, height: 32, marginRight: 8}} className="FBAvatarContainer instagram-avatar" src={avatar1}/>
                        <span className="post-title">{author1}</span>
                    </div>
                    <svg aria-label="Plus d’options" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Plus d’options</title><circle cx="12" cy="12" r="1.5"></circle><circle cx="6" cy="12" r="1.5"></circle><circle cx="18" cy="12" r="1.5"></circle></svg>
                </div>
                <video loop muted autoPlay playsInline style={{width: "100%", maxHeight: 450, objectFit: 'cover', objectAlign: "center center",  marginBottom: -6}}>
                    <source src={videoRef1} type="video/mp4" /> 
                </video>
                <div className="post-footer"> {/* New Footer */}
                    <div className="post-footer-left">
                        <svg aria-label="J’aime" class="x1lliihq x1n2onr6 xyb1xck" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>J’aime</title><path d="M16.792 3.904A4.989 4.989 0 0 1 21.5 9.122c0 3.072-2.652 4.959-5.197 7.222-2.512 2.243-3.865 3.469-4.303 3.752-.477-.309-2.143-1.823-4.303-3.752C5.141 14.072 2.5 12.167 2.5 9.122a4.989 4.989 0 0 1 4.708-5.218 4.21 4.21 0 0 1 3.675 1.941c.84 1.175.98 1.763 1.12 1.763s.278-.588 1.11-1.766a4.17 4.17 0 0 1 3.679-1.938m0-2a6.04 6.04 0 0 0-4.797 2.127 6.052 6.052 0 0 0-4.787-2.127A6.985 6.985 0 0 0 .5 9.122c0 3.61 2.55 5.827 5.015 7.97.283.246.569.494.853.747l1.027.918a44.998 44.998 0 0 0 3.518 3.018 2 2 0 0 0 2.174 0 45.263 45.263 0 0 0 3.626-3.115l.922-.824c.293-.26.59-.519.885-.774 2.334-2.025 4.98-4.32 4.98-7.94a6.985 6.985 0 0 0-6.708-7.218Z"></path></svg>
                        <svg aria-label="Commenter" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Commenter</title><path d="M20.656 17.008a9.993 9.993 0 1 0-3.59 3.615L22 22Z" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2"></path></svg>
                        <svg aria-label="Partager" class="x1lliihq x1n2onr6 xyb1xck" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Partager</title><line fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" x1="22" x2="9.218" y1="3" y2="10.083"></line><polygon fill="none" points="11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334" stroke="currentColor" stroke-linejoin="round" stroke-width="2"></polygon></svg>
                    </div>
                    <svg aria-label="Enregistrer" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Enregistrer</title><polygon fill="none" points="20 21 12 13.44 4 21 4 3 20 3 20 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></polygon></svg>
                </div>
                <div className="post-likes-comments"> {/* New likes and comments section */}
                    <div className="likes-count">88 J'aime</div>
                    <div style={{marginTop: 8}} />
                    <div>
                        <span className="comment-username">user123 </span>{comment1}
                    </div>
                    <div style={{marginTop: 4}} />
                    <div className="view-more-comments">Afficher les 18 commentaires</div>
                    <div style={{marginTop: 4}} />
                    <div className="timestamp">Il y a 1 jour</div>
                </div>
            </div>


            {/* Image Content */}
            <div className="feed-item image">
                <div className="post-header"> {/* Post header */}
                    <div className="post-header-left"> {/* So the restaurant icon and name are together */}
                        <img style={{width: 32, height: 32, marginRight: 8}} className="FBAvatarContainer" src={avatar2}/>
                        <span className="post-title">{author2}</span>
                    </div>
                    <svg aria-label="Plus d’options" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Plus d’options</title><circle cx="12" cy="12" r="1.5"></circle><circle cx="6" cy="12" r="1.5"></circle><circle cx="18" cy="12" r="1.5"></circle></svg>
                </div>
                <img src={imgRef1} alt="Instagram Post"  style={{width: "100%", maxHeight: 450, objectFit: 'cover', objectAlign: "center center", marginBottom: -6}}/>
                <div className="post-footer"> {/* New Footer */}
                    <div className="post-footer-left">
                        <svg aria-label="J’aime" class="x1lliihq x1n2onr6 xyb1xck" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>J’aime</title><path d="M16.792 3.904A4.989 4.989 0 0 1 21.5 9.122c0 3.072-2.652 4.959-5.197 7.222-2.512 2.243-3.865 3.469-4.303 3.752-.477-.309-2.143-1.823-4.303-3.752C5.141 14.072 2.5 12.167 2.5 9.122a4.989 4.989 0 0 1 4.708-5.218 4.21 4.21 0 0 1 3.675 1.941c.84 1.175.98 1.763 1.12 1.763s.278-.588 1.11-1.766a4.17 4.17 0 0 1 3.679-1.938m0-2a6.04 6.04 0 0 0-4.797 2.127 6.052 6.052 0 0 0-4.787-2.127A6.985 6.985 0 0 0 .5 9.122c0 3.61 2.55 5.827 5.015 7.97.283.246.569.494.853.747l1.027.918a44.998 44.998 0 0 0 3.518 3.018 2 2 0 0 0 2.174 0 45.263 45.263 0 0 0 3.626-3.115l.922-.824c.293-.26.59-.519.885-.774 2.334-2.025 4.98-4.32 4.98-7.94a6.985 6.985 0 0 0-6.708-7.218Z"></path></svg>
                        <svg aria-label="Commenter" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Commenter</title><path d="M20.656 17.008a9.993 9.993 0 1 0-3.59 3.615L22 22Z" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2"></path></svg>
                        <svg aria-label="Partager" class="x1lliihq x1n2onr6 xyb1xck" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Partager</title><line fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" x1="22" x2="9.218" y1="3" y2="10.083"></line><polygon fill="none" points="11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334" stroke="currentColor" stroke-linejoin="round" stroke-width="2"></polygon></svg>
                    </div>
                    <svg aria-label="Enregistrer" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Enregistrer</title><polygon fill="none" points="20 21 12 13.44 4 21 4 3 20 3 20 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></polygon></svg>
                </div>
                <div className="post-likes-comments"> {/* New likes and comments section */}
                    <div className="likes-count">32 J'aime</div>
                    <div style={{marginTop: 8}} />
                    <div>
                        <span className="comment-username">user123 </span>{comment2}
                    </div>  
                    <div style={{marginTop: 4}} />          
                    <div className="view-more-comments">Afficher les 2 commentaires</div>
                    <div style={{marginTop: 4}} />
                    <div className="timestamp">Il y a 1 jour</div>
                </div>
            </div>

            {/* Video Content 2 */}
            <div className="feed-item video">
                <div className="post-header">
                    <div className="post-header-left">
                        <img style={{width: 32, height: 32, marginRight: 8}} className="FBAvatarContainer  instagram-avatar" src={avatar3}/>
                        <span className="post-title" >{author3}<svg style={{marginLeft: 4, paddingBottom: 4, display: "inline-block", verticalAlign: "middle"}} aria-label="Vérifié" class="x1lliihq x1n2onr6" fill="rgb(0, 149, 246)" height="12" role="img" viewBox="0 0 40 40" width="12"><title>Vérifié</title><path d="M19.998 3.094 14.638 0l-2.972 5.15H5.432v6.354L0 14.64 3.094 20 0 25.359l5.432 3.137v5.905h5.975L14.638 40l5.36-3.094L25.358 40l3.232-5.6h6.162v-6.01L40 25.359 36.905 20 40 14.641l-5.248-3.03v-6.46h-6.419L25.358 0l-5.36 3.094Zm7.415 11.225 2.254 2.287-11.43 11.5-6.835-6.93 2.244-2.258 4.587 4.581 9.18-9.18Z" fill-rule="evenodd"></path></svg><br/><span className="sponsored-tag">Sponsorisé</span></span>
                    </div>
                    <svg aria-label="Plus d’options" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Plus d’options</title><circle cx="12" cy="12" r="1.5"></circle><circle cx="6" cy="12" r="1.5"></circle><circle cx="18" cy="12" r="1.5"></circle></svg>
                </div>
                
                <video loop muted playsInline autoPlay style={{width: "100%", maxHeight: 450, objectFit: 'cover', objectAlign: "center center", marginBottom: -6}} poster='/images/demo_pates.jpg'>
                <source src={videoRef2} type="video/mp4" />
                </video>                
                { // Conditionally render CTA button
                    <div  onClick={()=>navigate("/p/" + ctaDest + "?fromDemoInstantAd=true")} id="cta" className="cta-container" style={{ backgroundColor: "#FFFFFF" }}> {/* CTA Container */}
                        <div className="cta-text">{cta}</div>
                        <svg aria-label="Chevron droit" fill="currentColor" height="16" role="img" viewBox="0 0 24 24" width="16"><title>Chevron droit</title><path  transform="rotate(90 8 8)" d="M21 17.502a.997.997 0 0 1-.707-.293L12 8.913l-8.293 8.296a1 1 0 1 1-1.414-1.414l9-9.004a1.03 1.03 0 0 1 1.414 0l9 9.004A1 1 0 0 1 21 17.502Z"></path></svg>
                    </div>
                }
                <div className="post-footer"> {/* New Footer */}
                    <div className="post-footer-left">
                        <svg aria-label="J’aime" class="x1lliihq x1n2onr6 xyb1xck" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>J’aime</title><path d="M16.792 3.904A4.989 4.989 0 0 1 21.5 9.122c0 3.072-2.652 4.959-5.197 7.222-2.512 2.243-3.865 3.469-4.303 3.752-.477-.309-2.143-1.823-4.303-3.752C5.141 14.072 2.5 12.167 2.5 9.122a4.989 4.989 0 0 1 4.708-5.218 4.21 4.21 0 0 1 3.675 1.941c.84 1.175.98 1.763 1.12 1.763s.278-.588 1.11-1.766a4.17 4.17 0 0 1 3.679-1.938m0-2a6.04 6.04 0 0 0-4.797 2.127 6.052 6.052 0 0 0-4.787-2.127A6.985 6.985 0 0 0 .5 9.122c0 3.61 2.55 5.827 5.015 7.97.283.246.569.494.853.747l1.027.918a44.998 44.998 0 0 0 3.518 3.018 2 2 0 0 0 2.174 0 45.263 45.263 0 0 0 3.626-3.115l.922-.824c.293-.26.59-.519.885-.774 2.334-2.025 4.98-4.32 4.98-7.94a6.985 6.985 0 0 0-6.708-7.218Z"></path></svg>
                        <svg aria-label="Commenter" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Commenter</title><path d="M20.656 17.008a9.993 9.993 0 1 0-3.59 3.615L22 22Z" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2"></path></svg>
                        <svg aria-label="Partager" class="x1lliihq x1n2onr6 xyb1xck" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Partager</title><line fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" x1="22" x2="9.218" y1="3" y2="10.083"></line><polygon fill="none" points="11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334" stroke="currentColor" stroke-linejoin="round" stroke-width="2"></polygon></svg>
                    </div>
                    <svg aria-label="Enregistrer" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Enregistrer</title><polygon fill="none" points="20 21 12 13.44 4 21 4 3 20 3 20 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></polygon></svg>
                </div>
                <div className="post-likes-comments"> {/* New likes and comments section */}
                    <div className="likes-count">160 J'aime</div>
                    <div style={{marginTop: 8}} />
                    <div>
                        <span className="comment-username">user123 </span>{comment3}
                    </div>
                    <div style={{marginTop: 4}} />
                    <div className="view-more-comments">Afficher les 36 commentaires</div>
                    <div style={{marginTop: 4}} />
                    <div className="timestamp">Il y a 1 jour</div>
                </div>
            </div>
        </div>
        <div className="instagram-bottom-nav"> {/* New bottom navigation bar */}
            <svg aria-label="Accueil" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Accueil</title><path d="M22 23h-6.001a1 1 0 0 1-1-1v-5.455a2.997 2.997 0 1 0-5.993 0V22a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V11.543a1.002 1.002 0 0 1 .31-.724l10-9.543a1.001 1.001 0 0 1 1.38 0l10 9.543a1.002 1.002 0 0 1 .31.724V22a1 1 0 0 1-1 1Z"></path></svg>
            <svg aria-label="Découvrir" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Découvrir</title><path d="M19 10.5A8.5 8.5 0 1 1 10.5 2a8.5 8.5 0 0 1 8.5 8.5Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path><line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="16.511" x2="22" y1="16.511" y2="22"></line></svg>
            <svg aria-label="Reels" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Reels</title><line fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" x1="2.049" x2="21.95" y1="7.002" y2="7.002"></line><line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="13.504" x2="16.362" y1="2.001" y2="7.002"></line><line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="7.207" x2="10.002" y1="2.11" y2="7.002"></line><path d="M2 12.001v3.449c0 2.849.698 4.006 1.606 4.945.94.908 2.098 1.607 4.946 1.607h6.896c2.848 0 4.006-.699 4.946-1.607.908-.939 1.606-2.096 1.606-4.945V8.552c0-2.848-.698-4.006-1.606-4.945C19.454 2.699 18.296 2 15.448 2H8.552c-2.848 0-4.006.699-4.946 1.607C2.698 4.546 2 5.704 2 8.552Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path><path d="M9.763 17.664a.908.908 0 0 1-.454-.787V11.63a.909.909 0 0 1 1.364-.788l4.545 2.624a.909.909 0 0 1 0 1.575l-4.545 2.624a.91.91 0 0 1-.91 0Z" fill-rule="evenodd"></path></svg>
            <svg aria-label="Direct" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Direct</title><line fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" x1="22" x2="9.218" y1="3" y2="10.083"></line><polygon fill="none" points="11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334" stroke="currentColor" stroke-linejoin="round" stroke-width="2"></polygon></svg>
            <img style={{width: 24, height: 24}} className="FBAvatarContainer" src={userAvatar}/>
        </div>
    </div>
  );
};

export default InstagramFeedSimulator;
