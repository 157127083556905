import { getFirestore } from 'firebase/firestore';
import { doc, getDoc, updateDoc, increment, setDoc, getDocs, collection, query, where } from "firebase/firestore";

class AppAnalyticsManager{
    
    #db;
    docRef;
    #stats;
    #interval = 2000;
    totalDuration = 0;
    counter;

    constructor(storyId, preview, uid, clientId) {
        this.storyId = storyId;        
        this.preview = preview;  
        this.uid = uid;
        this.clientId = clientId;
    }

    init(){
        if (this.preview) return;

        this.#db = getFirestore();
        this.docRef = doc(this.#db, "stories", this.storyId );
        this.#stats = {
            itineraryNb: 0,
            bookingIntentNb: 0,
            shares: 0,
            viewNb: 0,
            returnNb: 0, 
            rating: 0,        
            mediaClicked: false,
            tourClicked: false,
            hoursClicked: false,
            reviewsClicked: false
          };        
    }
        
    updateViewNb(){
        if (this.preview) return;

        const updateViewNbAsync  = async()=>{
            // TODO this should not be blocking - make it async
            const webUserRef = doc(this.#db, "web_users", this.clientId + "_" + this.storyId);
            const webUser = await getDoc(webUserRef);
            if (! webUser.exists()){
                await setDoc(webUserRef, {storyId: this.storyId, uid: this.uid,});
                
                await updateDoc(this.docRef, {
                    viewNb: increment(1)
                });
                // TODO only mentioning viewNb should work but id doesnt ???
                this.#stats.viewNb = 1;
            }
            else {     
                // TODO count only a unique return after 30sec (remove bug then refresh)
                await updateDoc(this.docRef, {
                    returnNb: increment(1)
                });
                
                this.#stats.returnNb = this.#stats.returnNb + 1;
                this.updateRating(4.5);
            } 
        }
        updateViewNbAsync();
    }
      
    updateRating(rating){
        if (this.preview) return;

        const updateRatingAsync = async(rating) => {                        
            const storyRatingRef = doc(this.#db, "story_ratings", this.storyId + "_" + this.clientId);

            if (this.#stats.rating === 0){
                const ratingRef = collection(this.#db, "story_ratings");
                // Create a query against the collection.
                const q = query(ratingRef, where("storyId", "==", this.storyId), where("instanceId", "==", this.clientId));   
                const querySnapshot = await getDocs(q);
                
                if (querySnapshot.docs.length === 0){
                    await setDoc(storyRatingRef, {instanceId: this.clientId, storyId: this.storyId, uid: this.uid, rating: rating});
                    this.#stats.rating = rating;                        
                }
                else {        
                    if (rating > querySnapshot.docs[0].data().rating){   
                        this.#stats.rating = rating;          
                        await setDoc(storyRatingRef, {instanceId: this.clientId, storyId: this.storyId, uid: this.uid, rating: rating});
                    }
                    else {
                        this.#stats.rating = querySnapshot.docs[0].data().rating;         
                    }              
                }
            }
            else {     
                if (rating > this.#stats.rating){
                    this.#stats.rating = rating;
                    await setDoc(storyRatingRef, {instanceId: this.clientId, storyId: this.storyId, uid: this.uid, rating: rating});
                } 
            }         
        }
        updateRatingAsync(rating);
    }

    updateStats(state){
        if (this.preview) return;

        if (state.itineraryNb != null && this.#stats.itineraryNb === 0){
            updateDoc(this.docRef, {
            itineraryNb: increment(1)
            });

            // put in callback
            this.#stats.itineraryNb = 1;
            this.updateRating(4);
        }
        if (state.bookingIntentNb != null && this.#stats.bookingIntentNb === 0){
            updateDoc(this.docRef, {
            bookingIntentNb: increment(1)
            });

            // put in callback
            this.#stats.bookingIntentNb = 1;
            this.updateRating(4.5);
        }
        if (state.shares != null && this.#stats.shares === 0){
            updateDoc(this.docRef, {
            shares: increment(1)
            });

            // put in callback
            this.#stats.shares = 1;
            this.updateRating(4);
        } 
    }

    clickedHours(){
        if (this.preview) return;

        this.#stats.hoursClicked = true;
        let newRating = 2;
        if ((this.#stats.tourClicked && ! this.#stats.mediaClicked) || (! this.#stats.tourClicked && this.#stats.mediaClicked)){
          newRating = 2.5;
        }
        else if (this.#stats.tourClicked && this.#stats.mediaClicked){
          newRating = 3;
        }
        this.updateRating(newRating);
    }

    clickedReviews(){
        if (this.preview) return;

        this.#stats.reviewsClicked = true;
        let newRating = 2;
        if ((this.#stats.tourClicked && ! this.#stats.mediaClicked) || (! this.#stats.tourClicked && this.#stats.mediaClicked)){
          newRating = 2.5;
        }
        else if (this.#stats.tourClicked && this.#stats.mediaClicked){
          newRating = 3;
        }
        this.updateRating(newRating);
    }

    clickedLocation(){
        if (this.preview) return;

        this.updateStats({itineraryNb: 1});
    }

    clickedShare(){
        if (this.preview) return;

        this.updateStats({shares: 1});
    }

    clickedBook(){
        if (this.preview) return;

        this.updateStats({bookingIntentNb: 1});        
    }

    clickedMenu(){
        if (this.preview) return;

        this.#stats.mediaClicked = true;        
        let newRating = 2;
        if ((this.#stats.tourClicked && ! this.#stats.hoursClicked && ! this.#stats.reviewsClicked) || (! this.#stats.tourClicked && this.#stats.hoursClicked && this.#stats.reviewsClicked)){
          newRating = 2.5;
        }
        else if (this.#stats.tourClicked && (this.#stats.hoursClicked || this.#stats.reviewsClicked)){
          newRating = 3;
        }
        this.updateRating(newRating);
    }

    clickedInside(){  
        if (this.preview) return;

        this.#stats.tourClicked = true;        
        let newRating = 2;
        if ((this.#stats.mediaClicked && ! this.#stats.hoursClicked && ! this.#stats.reviewsClicked) || (! this.#stats.mediaClicked && this.#stats.hoursClicked && this.#stats.reviewsClicked)){
          newRating = 2.5;
        }
        else if (this.#stats.mediaClicked && (this.#stats.hoursClicked || this.#stats.reviewsClicked)){
          newRating = 3;
        }
        this.updateRating(newRating);
    }

    visitDurationCounter() {   
        if (this.preview) return;

        if (this.totalDuration < 30 && this.docRef != null){
            updateDoc(this.docRef, {
                duration: increment(2),// do not use decimal here
            });
            this.totalDuration = this.totalDuration + 2;             
            if (this.totalDuration === 10){
                this.updateRating(1);
            }      
        }
        
    }

}

export default AppAnalyticsManager;
